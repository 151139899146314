<template>
  <section class="m-index-search" :style="`background-color:${background}`">
      <div v-if="offsetTop < 170" class="m-search-wrap  m-padding">
        <router-link to="/zh/search" class="m-search-input">
            <img class="m-logo" src="@/assets/index/LOGO.png" alt="logo">
            <span>请输入关键词</span>
            <img class="m-search-icon" src="@/assets/index/Search@2x.png" alt="search">
        </router-link>
        <img class="m-lang" src="@/assets/index/zh.png" alt="zh" @click="changeLang">
      </div>
      <div v-else class="m-search-else  m-padding">
          <div class="m-search-other" >
            <img class="m-logo" src="@/assets/index/logo-a@3x.png" alt="logo" @click="$emit('top', true)">
            <img class="m-lang" src="@/assets/index/icon_china-en_b@3x.png" alt="zh" @click="changeLang">
          </div>
        <router-link to="/zh/search" class="m-other-input">
            <img class="m-search-icon" src="@/assets/index/Search@2x.png" alt="search">
            <span>请输入关键词</span>
        </router-link>
      </div>
  </section>
</template>

<script>
export default {
  name:'IndexSearch',
  props:['background', 'offsetTop'],
  methods: {
    changeLang() {
      // 切换语言
      let lang = localStorage.getItem('lang')
      if(!lang) {
        lang = 'zh'
      }
      if(lang == 'zh' || this.$route.path == '/zh') {
        localStorage.setItem('lang', 'en')
        this.$router.push('/en')
      }else{
        localStorage.setItem('lang', 'zh')
        this.$router.push('/zh')
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .m-index-search {
    background: #fff;
    .m-search-wrap {
      height: 48px;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 99;
      transition: all 0.5s;
      .m-search-input {
        flex: 1;
        background-color: rgba(255, 255, 255, 0.341);
        border-radius: 20px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .m-logo {
          width: 128px;
          height: 20px;
          margin-right: 17px;
        }
        span {
          font-size: 14px;
          color: #fff;
          letter-spacing: 0.37px;
        }
        .m-search-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      img {
        width: 30px;
        height: 30px;
        margin-left: 10px;
      }
    }
    .m-search-else{
      transition: all 0.5s;
      position: fixed;
      width: 100%;
      top: 8px;
      left: 0;
      z-index: 99;
    }
    .m-search-other {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 99;
      position: relative;
      .m-logo {
        width: 150px;
      }
      .m-lang {
        width: 32px;
      }
      
    }
    .m-other-input {
      background-color: #fff;
      border-radius: 20px;
      border:1px solid #e1e1e1;
      height: 8.6vw;
      display: flex;
      align-items: center;
      margin: 5px 16px 0;
      padding-left:16px;
      .m-search-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      span {
        font-size: 14px;
        color: #999;
        letter-spacing: 0.37px;
      }
    }
}
</style>