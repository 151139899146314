<template>
    <section class="m-index-active">
        <div class="m-active-top" @click="$router.push({path:`/${lang}/active`,query:{id:data.id}})" :style="{'background-image': 'url('+ data.banner_pic_url + ')'}"></div>
        <div class="m-active-center" :style="{'background-image': 'linear-gradient(180deg,' + data.banner_bgcolor + ', #fff)'}">
            <van-swipe height="190" ref="swipe" :stop-propagation="false">
                <van-swipe-item v-for="(item,index) in data.list" :key="index">
                    <ul class="m-active-wrap-skeleton" v-if="data.length <= 0 && loading">
                        <li class="m-active-item" v-for="(item, index) in 3" :key="index">
                            <div class="m-active-goods">
                                <div class="goods-img"></div>
                                <div class="goods-title">
                                    <p></p>
                                    <p></p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul class="m-active-wrap" v-else>
                        <li class="m-active-item" v-for="(it, index) in item" :key="index">
                            <router-link :to="{path:`/zh/goods/detail/${it.goods_id}`}" class="m-active-goods">
                                <div>
                                    <img :src="it.pic_image" alt="" />
                                    <span class="discount" v-if="((it.activity_min_price / it.min_price*10)).toFixed(1) != 10">{{((it.activity_min_price / it.min_price*10)).toFixed(1)}}折</span>
                                </div>
                                <p class="van-multi-ellipsis--l2">
                                    <span>{{it.name}}</span>
                                </p>
                                <div class="goods-price">
                                    <span>$</span>
                                    <span>{{it.activity_min_price}}</span>
                                    <span v-if="it.activity_min_price != it.min_price">${{it.min_price}}</span>
                                </div>
                            </router-link>
                        </li>
                    </ul> 
                </van-swipe-item>
            </van-swipe>
        </div>
    </section>
</template>

<script>
// import { getLanguage } from '@/untils/js/common.js'
export default {
    name: "IndexActive",
    props:['data', 'loading', 'isPre'],
    data() {
        return {
            lang: 'zh'
        }
    },
    created() {
        // let lang = getLanguage()
        // if(lang == 'zh') {
        //     this.lang = 'zh'
        // } else {
        //     this.lang = 'en'
        // }
    }
};
</script>

<style lang="less" scoped>
.m-index-active {
    width: 100%;
    box-shadow: 0px 4px 8px rgba(153, 153, 153, 0.1);
    border-radius: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    // background: linear-gradient(180deg, #ff7d82 -39.31%, #ffffff 64.02%);
    .m-active-top {
        width: 100%;
        height: 86px;
        // margin-bottom: 6px;
        // background-image: url("../../assets/index/active-top.png");
        background-size: 100% 100%;
        background-position: center;
    }
    .m-active-center {
        padding-top: 4px;
        .m-active-wrap {
            width: 331px;
            margin: 0 10px;
            display: flex;
            // flex-wrap: wrap;
            // justify-content: space-between;
            list-style: none;
            .m-active-item {
                width: 106px;
                height: 150px;
                border-radius: 3px;
                margin-right: 7px;
                &:last-child {
                    margin: 0;
                }
                .m-active-goods {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    color: #333;
                    & > div:nth-of-type(1) {
                        overflow: hidden;
                        position: relative;
                        width: 106px;
                        height: 100px;
                        background: #fff;
                        box-shadow: 0px 2px 6px rgba(153, 153, 153, 0.15);
                        border-radius: 5px;
                        margin-bottom: 4px;
                        & > img {
                            width: 100%;
                            height: 100%;
                        }
                        .discount {
                            display: block;
                            width:45px;
                            height:18px;
                            line-height: 18px;
                            text-align: center;
                            position: absolute;
                            top:4px;
                            left:0;
                            background: #EF4A29;
                            // background:url('../../assets/active/coupon_bg.png') no-repeat center;
                            // background-size: 100% 100%;
                            font-size: 12px;
                            font-family: 'FuturaBT-BoldItalic';
                            font-weight: bold;
                            color: #fff;
                        }
                        
                    }
                    &>p {
                        font-size: 12px;
                        margin-top: 8px;
                        letter-spacing: 0.31px;
                        line-height: 16px;
                        height: 30px;
                    }
                    .goods-price {
                        display: flex;
                        align-items: center;
                        align-items: flex-end;
                        font-size: 11px;
                        font-weight: 400;
                        color: #eb5940;
                        & > span:nth-of-type(2) {
                            font-size: 11px;
                            font-weight: bold;
                        }
                        & > span:nth-of-type(3) {
                            margin-left: 3px;
                            font-size: 11px;
                            color: #999999;
                            text-decoration: line-through;
                        }
                    }
                }
            }
        }
        .m-active-wrap-skeleton {
            width: 331px;
            margin: 0 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            list-style: none;
            .m-active-item {
                width: 106px;
                height: 150px;
                border-radius: 3px;
                .m-active-goods {
                    width: 100%;
                    color: #333;
                    position: relative;
                    .goods-img {
                        width: 100%;
                        height: 100px;
                        border-radius: 3px;
                        background: #f1f1f1;
                    }
                    .goods-title {
                        width: 100%;
                        height: 40px;
                        margin-top: 5px;
                        >p {
                            &:nth-of-type(1){width:100%;height:20px;background: #f1f1f1}
                            &:nth-of-type(2){width:40%;height:20px;background: #f1f1f1;margin-top:5px}
                        }
                    }
                }
            }
        }
    }
}
</style>