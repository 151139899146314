<template>
  <section class="m-index-shop">
      <ul class="m-shop-wrap">
        <li class="m-shop-item" v-for="(it, index) in brandList" :key="index">
          <router-link :to="it.m != 0 ? `zh/merchant/index/${it.m}` : `/zh/search?skey=${it.key}&b=1`" class="m-shop">
            <img v-lazy="it.img"   alt="Merchant">
          </router-link>
        </li>
        <li class="m-shop-item"></li>
      </ul>

  </section>
</template>

<script>

export default {
    name:'IndexBrand',
    data() {
        return {
            brandList: [
                {id: 1, img: require('@/assets/brand/xm.png'), key: '小米', m: 0},
                {id: 2, img: require('@/assets/brand/bj.png'), key: '杯具熊', m: 0},
                {id: 3, img: require('@/assets/brand/cg.png'), key: '晨光', m: 0},
                {id: 4, img: require('@/assets/brand/jd.png'), key: '手办', m: 922},
                {id: 5, img: require('@/assets/brand/hf.png'), key: '金稻', m: 0},
                {id: 6, img: require('@/assets/brand/mz.png'), key: '大加拿', m: 905}
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.m-index-shop {
  padding-left: 16px;
  margin-bottom: 5px;
  .m-shop-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
    width: auto;
    height: 230px;
    padding-bottom: 10px;
    transition: all 0.5s ease;
    overflow-x: auto;
    overflow-y: hidden;
    .m-shop-item{
      margin-right: 12px;
      display: block;
      background:#fff;
      box-shadow:0px 10px 15px 0px rgba(217,217,217,0.15);
      border-radius:4px;
      height: 100px;
      width:200px;
    }
    .m-shop-item:last-child{
      width: 8px;
      height: 276px;
    }
    .m-shop {
      display: inline-block;
      color: #333;
      width:200px;
      height:100px;
      &>img {
        width: 200px;
        height: 100px;
        border-radius: 5px;
      }
      .shop-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px ;
        padding-bottom: 8px;
        &>img {
          height: 16px;
          display: inline-block;
          margin: 0 4px 0 0;
          vertical-align: bottom;
        }
       &>p {
          flex: 1;
          font-size:14px;
          font-weight:400;
          color:#333;
          line-height:16px;
          padding-right: 2px;
          width: 0;
          padding-right:8px;
       } 
       &>a{
         width:46px;
         height:18px;
         background:linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%);
         border-radius:12px;
         text-align: center;
         font-size:12px;
         font-weight:400;
         color:rgba(255,255,255,1);
         line-height:18px;
         margin-left: 4px;
       }
      }
    }
    .m-shop-item:nth-child(odd){
      margin-bottom: 12px;
    }
  }
}
</style>