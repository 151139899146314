<template>
    <div class="m-section"  @scroll="handleScroll($event)" ref="scrollDom">
      <ul class="m-goods-columns">
          <!-- 骨架屏加载开始 -->
          <Skeleton v-if="data.length <= 0 && loading" :columns="columns" />
          <!-- 骨架屏加载结束 -->
    
          <li v-for="(item, index) in data" :key="index" :class="{'bg': columns != 1}" :style="{width:columns == 1 ? '100%' : 'auto'}">
            <!-- 列表格式为 一行一列 -->
            <router-link :to="{path: `/zh/goods/detail/${item.id}`,
            query: {pre: (preList.indexOf($route.path) != -1 || $route.query.s == 'presale' || item.pre_info.is_pre == 1) ? 1 : 0}}"
            class="goods-columns-1">
              <img v-lazy="item.image_url ? item.image_url : item.goods_pic_url" alt="goods">
              <div class="eleven" v-if="item.merchant_id == 1 && preList.indexOf($route.path) == -1 && item.pre_info.is_pre == 0 && elevenShow"></div>
              <div class="goods-info">
                <p class="van-multi-ellipsis--l2">
                  <span class="m-mooby" v-if="item.merchant_id == 1 && preList.indexOf($route.path) == -1 && item.pre_info.is_pre == 0">自营</span>
                  <span class="m-mooby"
                  v-if="preList.indexOf($route.path) != -1 || item.pre_info.is_pre == 1">预售</span>
                  {{item.goods_info ? item.goods_info.name : ''}}
                </p>     
                <div class="goods-price">
                  <div> 
                    <p v-if="item.pre_info.is_pre == 1 && item.pre_info.pre_min_price != null">
                      <span>$</span><span>{{item.pre_info.pre_min_price}}</span>
                      <!-- <span v-if="item.off != 0&&item.pre_info.pre_min_price">${{item.pre_info.pre_min_price}}</span> -->
                    </p>
                    <p v-else>
                      <span>$</span><span>{{(item.activity_min_price *1).toFixed(0) != 0 ? item.activity_min_price : (item.min_price * (item.off == 0 ? 1 : item.off / 100)).toFixed(2)}}</span>
                      <span v-if="(item.activity_min_price *1).toFixed(0) != 0 ? (item.activity_min_price != item.min_price? true : false) : item.off != 0">${{item.min_price}}</span>
                    </p>
                  </div>
                  <img  @click.prevent="handleCart(item.id, $event)" src="@/assets/index/iocn-gouwu@2x.png" alt="cart">
                </div>
                <span class="discount">{{100  - item.off}}% off 抢购</span>
              </div>
            </router-link>
                
          </li>
          
          <li class="goods-columns-2 hide-columns" :class="{'goods-columns-3': columns == '3'}"></li>
          <li class="goods-columns-2 hide-columns" :class="{'goods-columns-3': columns == '3'}"></li>
      </ul>
    </div>
  </template>
  
  <script>
  import Skeleton from '@/components/en/skeleton'
  export default {
    name:'GoodsColumns',
    components: { Skeleton },
    props: {
      data: {
        type: Array,
        default: () => {
          return []
        },
      },
      columns: {
        type: Number,
        default: 2,
      },
      priceLine: {
        type: Boolean,
        default: true
      },
      cart: {
        type: Boolean,
        default: true
      },
      loading: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        timer: false,
        preList: ['/zh/presale', '/zh/subsale'],
        elevenShow: false // 圣诞活动边框显示
      }
    },
    methods:{
      handleScroll(e){
        // if (e.target.scrollLeft - this.$refs.scrollDom.scrollLeft >= 10 && !this.timer) {
        //   this.timer = setInterval(() => {
        //     if (this.$refs.scrollDom.scrollLeft < this.$refs.scrollDom.clientWidth) {
        //      this.$refs.scrollDom.scrollLeft += 5;
        //     }else{
        //       clearInterval(this.timer)
        //       this.timer = false
        //     }
        //   }, 10)
        // }
      },
      handleCart(goods,e){
        this.$emit('cartClick',goods,e)
      }
    },
  }
  </script>
  
  <style lang="less" scoped>
  .m-section {
    // padding: 16px 0 0 0;
    width: 350px;
    box-sizing: border-box;
    border-radius: 12px;
    margin: auto;
    overflow-x: auto;
    z-index: 9;
    background: linear-gradient(180deg, #FFE7E7 0%, #FFE7E7 0.01%, #FFFFFF 100%);
  }
  .m-goods-columns {
    width: 1050px;
     margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    position: relative;
   .bg {
     width: 318px !important;
     margin: 16px 16px 0 16px;
    //  margin-bottom: 16px;
     background: #fff;
     border-radius: 5px;
   }
   .goods-columns-1 {
     background: #fff;
     width: 100%;
     display: flex;
     box-shadow:0px 10px 15px 0px rgba(217,217,217,0.15);
     border-radius:5px;
     background: #fff;
     position: relative;
     .m-mooby {
        vertical-align: 2px;
        line-height: 14px;
     }
     .discount {
       display: block;
       width: 100%;
       font-size: 14px;
       text-align: center;
       padding: 3px 24px;
       color:#fff;
       background: #EA6A67;
       border-radius: 24px;
       font-weight: 500;
     }
     .eleven {
       position: absolute;
       top: 0;
       left: 0;
       width: 126px;
       height: 126px;
       border-radius:5px 0 0px 5px;
       background: url('../../assets/index/eleven.png') no-repeat center;
       background-size: 100% 100%;
     }
     &>img {
       width: 126px;
       height: 126px;
       border-radius:5px 0 0px 5px;
       margin-right: 11px;
     }
     .goods-info {
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       flex: 1;
       padding-top: 10px;
       &>p {
        font-size: 14px;
        letter-spacing: 0.31px;
        line-height: 20px;
        font-weight: 400;
        height: 40px;
        color: #333;
        padding-right: 10px;
       }
     }
     .goods-price {
       display: flex;
       align-items: center;
       justify-content: space-between;
       margin:6px 0  6px 0;
       font-size: 12px;
       &>div {
         display: flex;
         align-items: center;
         &>p:nth-of-type(1){
            color: #ED2A24;
            margin-right: 4px;
            &>span:nth-of-type(2){
              font-size: 18px;
            }
            &>span:nth-of-type(3){
              margin-top: 2px;
              margin-left:5px;
              font-size: 10px;
              color: #999999;
              text-decoration:line-through;
            }
          }
       }
       &>img {
          width: 40px;
          height: 40px;
        }
     }
   }
    .hide-columns {
      height: 0;
      opacity: 0;
      margin: 0;
    }
  }
  </style>