<template>
    <section class="m-index-landing">
        <div class="m-landing-wrap-skeleton" v-if="data.length <= 0 && loading">
            <div class="m-landing-top"></div>
            <ul class="m-landing-center">
                <li class="m-active-item" v-for="(item, index) in 4" :key="index">
                    <div class="goods-img"></div>
                    <div class="goods-price"></div>
                </li>
            </ul>
        </div>
        <div class="m-landing" v-else>
            <div class="m-landing-content" v-for="(item, index) in data" :key="index" :style="{'background-image': 'linear-gradient(180deg,' + item.banner_bgcolor + ', #fff)'}">
                <div class="m-landing-top" @click="$router.push(`/${lang}/landing/${item.id}`)">
                    <img :src="item.banner_pic_url" alt="">
                </div>
                <div class="m-landing-center">
                    <ul class="m-landing-wrap">
                        <li class="m-landing-item" v-for="(it,i) in item.goods_list" :key="i">
                            <router-link :to="{path:`/zh/goods/detail/${it.goods_id}`}" class="m-active-goods">
                                <div class="goods-pic">
                                    <img :src="it.pic_image" alt="">
                                </div>
                                <div class="goods-price">
                                    <span>$</span>
                                    <span>{{it.activity_min_price}}</span>
                                    <span>${{it.min_price}}</span>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="line"></div>
        </div>
    </section>
</template>
<script>
// import { getLanguage } from '@/untils/js/common.js'
export default {
    name: "IndexLanding",
    props: ['data', 'loading'],
    data() {
        return {
            lang: 'zh'
        }
    },
    created() {
        // let lang = getLanguage()
        // if(lang == 'zh') {
        //     this.lang = 'zh'
        // } else {
        //     this.lang = 'en'
        // }
    }
};
</script>
<style scoped lang="less">
    .m-index-landing {
        box-shadow: 0px 4px 8px rgba(153, 153, 153, 0.1);
        height: 176px;
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: 5px;
        display: flex;
        .m-landing-wrap-skeleton {
            padding: 12px 8px 16px;
            width: 100%;
            box-sizing: border-box;
            .m-landing-top {
                width: 100%;
                height: 34px;
                background: #f1f1f1;
                margin-bottom: 12px;
            }
            .m-landing-center {
                display: flex;
                justify-content: space-between;
                list-style: none;
                .m-active-item {
                    width: 80px;
                    height: 89px;
                    .goods-img {
                        width: 80px;
                        height: 80px;
                        margin-bottom: 4px;
                        background: #f1f1f1;
                    }
                    .goods-price {
                        width: 46px;
                        height: 14px;
                        background: #f1f1f1;
                    }

                }
            }
        }
        .m-landing {
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: relative;
            background: rgb(red, green, #fafcdd);
            .m-landing-content {
                flex: 1;
                padding: 12px 3px 16px 8px;
                &:nth-child(2) {
                    padding: 12px 8px 16px 2px;
                }
                .m-landing-top {
                    margin-bottom: 12px;
                    width: 164px;
                    height: 34px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .m-landing-center {
                    .m-landing-wrap {
                        display: flex;
                        list-style: none;
                        .m-landing-item {
                            margin-right: 4px;
                            .goods-pic {
                                width: 80px;
                                height: 80px;
                                border-radius: 5px;
                                margin-bottom:4px;
                                &>img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .goods-price {
                                text-align: center;
                                color: #EB5940;
                                font-size: 12px;
                                &>span:nth-child(2) {
                                    font-weight: bold;
                                    font-size: 14px;
                                }
                                &>span:nth-child(3) {
                                    display:block;
                                    color: #999999;
                                    text-decoration: line-through;
                                }
                            }
                        }
                    }
                }
            }
            .line {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0.5px;
                height: 145px;
                background: rgba(255, 159, 41, 0.5);
            }
        }
    }
</style>